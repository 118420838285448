import { capitalizeFirstLetterOfEachWord } from '../../utilities/stringUtils';
import { monthDayTimeFormat, timeFormat } from '../../utilities/dateUtils';
import { getLocation } from '../../utilities/googleMaps';
import format from 'date-fns/format';
import { addSpaceAfterCommas } from '../../utilities/stringUtils';
import { getTimeSinceDate } from '../../utilities/dateUtils';
import {
  trailerTypeMap,
  DeadheadSearch,
  SortBy,
  DeadheadStopSearchResponse,
  DeadheadSearchQuery,
  DeadheadQueryParams,
  RecentSearches,
  FindLoadsTableData,
} from './findLoadsTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const getStopDetails = (
  stop: DeadheadStopSearchResponse,
  deadheadMiles?: number
) => {
  return {
    cityStateText: `${capitalizeFirstLetterOfEachWord(stop.city)}, ${
      stop.state
    }`,
    arrivalText: `${monthDayTimeFormat(stop.scheduledArriveEarly)}${
      stop.scheduledArriveLate
        ? `-${timeFormat(stop.scheduledArriveLate, stop.scheduledArriveEarly)}`
        : ''
    }`,
    deadheadText: deadheadMiles
      ? deadheadMiles > 0
        ? `${deadheadMiles}m Deadhead`
        : 'No Deadhead'
      : '',
    type: stop.stopType,
    zip: stop.zip,
    appointmentRequired: stop.appointmentRequired !== 'N',
  };
};

export const getDetails = (
  loadBoardData: DeadheadSearch
): FindLoadsTableData => {
  return {
    id: loadBoardData.id,
    stops: loadBoardData.stops.map((x, index, array) => {
      // is first
      if (index === 0) {
        return getStopDetails(x, loadBoardData.deadheadMiles);
      }
      // is last
      if (index === array.length - 1) {
        return getStopDetails(x, loadBoardData.deliveryDeadheadMiles);
      }
      return getStopDetails(x);
    }),
    trailerType: trailerTypeMap.get(loadBoardData.equipmentType) || 'Unknown',
    weight: loadBoardData.weight
      ? `${loadBoardData.weight.toLocaleString()} lbs`
      : 'Unknown',
    loadType: loadBoardData.orderMode === 'T' ? 'FTL' : 'Partial',
    distance: loadBoardData.moveDistance
      ? `${loadBoardData.moveDistance.toLocaleString()} Miles`
      : '',
    price: loadBoardData.price
      ? loadBoardData.price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : '--',
    hasMultipleStops: loadBoardData.stops.length > 2,
    phone: loadBoardData.phone,
    notes: loadBoardData.comment,
    isAvailable: loadBoardData.isAvailable,
    isBookItNowDisabled: loadBoardData.isBookItNowDisabled,
    commodity: loadBoardData.commodity,
  };
};

export const getTableData = (loadboardData: DeadheadSearch[]) => {
  return loadboardData.map(getDetails);
};

export const sortData = (sortBy: SortBy, data: DeadheadSearch[]) => {
  if (sortBy === 'Lowest Deadhead') {
    return data.sort((a, b) => {
      if (a.deadheadMiles === undefined) {
        return 1;
      }
      if (b.deadheadMiles === undefined) {
        return -1;
      }
      return a.deadheadMiles - b.deadheadMiles;
    });
  }
  if (sortBy === 'Highest Price') {
    return data.sort((a, b) => {
      if (a.price === null) {
        return 1;
      }
      if (b.price === null) {
        return -1;
      }
      return b.price - a.price;
    });
  }
  if (sortBy === 'Shortest Trip') {
    return data.sort((a, b) => {
      return Number(a.moveDistance) - Number(b.moveDistance);
    });
  }
  if (sortBy === 'Longest Trip') {
    return data.sort((a, b) => {
      return Number(b.moveDistance) - Number(a.moveDistance);
    });
  }
  return data;
};

export const getLoadMarkers = (stops: DeadheadStopSearchResponse[]) => {
  return stops.map((x) => ({
    lat: x.latitude,
    lng: -x.longitude,
    type: x.stopType === 'PU' ? 'PICKUP' : 'DELIVERY',
  }));
};

export const queryParamsToDeadheadQuery = async (
  queryParams: DeadheadQueryParams
) => {
  const {
    pickupCityZip,
    pickupDeadhead,
    pickupStates,
    pickupProvince,
    deliveryCityZip,
    deliveryDeadhead,
    deliveryStates,
    deliveryProvince,
    equipmentType,
    pickupDate,
    orderMode,
  } = queryParams;

  const query: DeadheadSearchQuery = {};

  if (pickupCityZip) {
    const [lat, lng] = await getLocation(pickupCityZip);
    query.latitude = lat;
    query.longitude = lng;
    query.pickupDeadheadMiles = Number(pickupDeadhead) || 200;
  }

  if (pickupStates) {
    query.pickupStates = pickupStates;
  }

  if (pickupProvince) {
    query.pickupStates = pickupProvince;
  }

  if (deliveryStates) {
    query.deliveryStates = deliveryStates;
  }
  if (deliveryProvince) {
    query.deliveryStates = deliveryProvince;
  }

  if (deliveryCityZip) {
    const [lat, lng] = await getLocation(deliveryCityZip);
    query.deliveryLatitude = lat;
    query.deliveryLongitude = lng;
    query.deliveryDeadheadMiles = Number(deliveryDeadhead) || 200;
  }

  if (equipmentType) {
    query.equipmentType = equipmentType.replace('Specialized', 'Other');
  }

  if (pickupDate) {
    query.pickupDate = pickupDate;
  }

  if (orderMode) {
    query.orderMode = orderMode;
  }

  return query;
};

export const recentSearchesToListData = (
  recentSearch: RecentSearches,
  t: TFunction
) => {
  return {
    pickupLocationText:
      addSpaceAfterCommas(recentSearch.pickupStates) ||
      addSpaceAfterCommas(recentSearch.pickupProvince) ||
      recentSearch.pickupCityZip ||
      t('Anywhere'),
    deliveryLocationText:
      addSpaceAfterCommas(recentSearch.deliveryStates) ||
      addSpaceAfterCommas(recentSearch.deliveryProvince) ||
      recentSearch.deliveryCityZip ||
      t('Anywhere'),
    date: recentSearch.pickupDate || format(new Date(), 'MM/dd/yyyy'),
    deadheadMiles: recentSearch.pickupDeadhead,
    equipmentType: addSpaceAfterCommas(recentSearch.equipmentType) || 'Van',
    when: getTimeSinceDate(recentSearch.when),
  };
};
