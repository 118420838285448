import React, { useState, useContext, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useMyLanesData from '../features/myLanesLegacy/useMyLanesData';
import { getCurrentUser } from '../utilities/authUtils';
import { useAbly } from './useAbly';

export interface LanesContextProp {
  loadsCountLegacy: number;
  lanesCount: number;
  toolTipMsg: string;
  setLoadsCount?: React.Dispatch<SetStateAction<number>>;
  setToolTipMsg?: React.Dispatch<SetStateAction<string>>;
  setLanesCount?: React.Dispatch<SetStateAction<number>>;
}

const LanesContext = React.createContext<LanesContextProp>({
  loadsCountLegacy: 0,
  lanesCount: 0,
  toolTipMsg: '',
});

LanesContext.displayName = 'LanesContext';

export const LanesProvider = ({ children }: any) => {
  const [loadsCount, setLoadsCount] = useState<number>(0);
  const [lanesCount, setLanesCount] = useState<number>(0);
  const [toolTipMsg, setToolTipMsg] = useState<string>('');
  const { t } = useTranslation();
  const currentUser = getCurrentUser();
  const { ably } = useAbly('ryanstran:lanesCron');

  const { lanes, laneSearchData } = useMyLanesData();

  React.useEffect(() => {
    if (currentUser) {
      const userId = currentUser._id;
      const channelName = `ryanstran:lanesCron:${userId}`;
      const channel = ably.channels.get(channelName);
      channel.subscribe((event) => {
        console.log(
          'event.data.totalLoadsCount :>> ',
          event.data.totalLoadsCount
        );
        setLoadsCount(event.data.totalLoadsCount);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, ably]);

  React.useEffect(() => {
    if (lanes.length !== 0) {
      setLanesCount(lanes.length);
      let count = 0;
      if (laneSearchData) {
        laneSearchData.forEach((item) => {
          count += item.loads.length;
        });
      }
      setLoadsCount(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lanes]);

  React.useEffect(() => {
    if (lanesCount > 0 && loadsCount === 0) {
      setToolTipMsg(t('No loads available'));
    } else if (lanesCount > 0 && loadsCount > 0) {
      setToolTipMsg(t('Loads available'));
    } else if (lanesCount === 0) {
      setToolTipMsg(t('Add lanes for notifications'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lanesCount, loadsCount, lanes]);

  return (
    <LanesContext.Provider
      value={{
        loadsCountLegacy: loadsCount,
        toolTipMsg,
        lanesCount,
        setLoadsCount,
        setToolTipMsg,
        setLanesCount,
      }}
    >
      {children}
    </LanesContext.Provider>
  );
};

export const useLanes = () => {
  const context = useContext(LanesContext);
  if (!context) {
    throw new Error('useLanes must be used within an LanesProvider');
  }
  return context;
};
